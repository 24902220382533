<!-- 模块说明 -->
<template>
  <div class="container" v-loading="loading">
    <subHead :active="3" :subpageActive="activeName"></subHead>
    <div class="banner">
      <div class="title">
        <h5>海恒动态</h5>
        <p>Haiheng Dynamics</p>
      </div>
    </div>
    <div class="tabs">
      <div class="content">
        <div
          class="typeList"
          :class="{ on: activeName == index }"
          v-for="(item, index) in typeList"
          :key="index"
          @click="changeTab(index)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div class="content" style="margin-bottom: 150px">
      <div
        class="hotspotList flex"
        v-for="(item, index) in hotspotList"
        :key="index"
        @click="jumpTo(item, index)"
      >
        <div class="left flex">
          <el-image
            :src="handelHttps(item.articleThumbnailUrl)"
            fit="cover"
          ></el-image>
          <div class="wnzi">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.abstractOfArticle }}</div>
          </div>
        </div>
        <div class="tiem">
          <div class="yy">{{ Dateformat(item.releaseTime, "YYYY") }}年</div>
          <div class="mm">{{ Dateformat(item.releaseTime, "MM-DD") }}</div>
          <div class="more">MORE</div>
        </div>
      </div>
      <el-row>
        <el-pagination
          :current-page.sync="currentPage"
          :page-size="pageSize"
          background
          layout="total,sizes, prev, pager, next,jumper"
          :total="totalNum"
          @current-change="handelCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="[5, 10, 20]"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import subHead from "../../components/subHead.vue";
export default {
  components: { subHead },
  props: [],
  data() {
    return {
      typeList: [
        {
          value: "COMPANY_NEWS",
          label: "公司新闻",
        },
        {
          value: "MEDIA_REPORT",
          label: "媒体报道",
        },
      ],
      hotspotList: [],
      activeName: 0,
      loading: false,
      totalNum: 0,
      currentPage: 1,
      pageSize: 5,
    };
  },
  watch: {
    "$route.query.show": {
      handler(newVal) {
        this.activeName = +newVal || 0;
        this.getArticleListPage();
      },
      deep: true,
    },
  },
  created() {
    this.activeName = +this.$route.query.show;
    this.getArticleListPage();
  },
  methods: {
    changeTab(i) {
      this.activeName = i;
      this.getArticleListPage();
    },
    //获取文章列表
    getArticleListPage() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        releaseStatus: "HAVE_RELEASED",
        type: this.typeList[this.activeName].value,
      };
      this.loading = true;
      this.http
        .get("/article/getArticleListPage", params)
        .then((res) => {
          this.hotspotList = res.data.data;
          this.totalNum = res.data.totalRecords;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    jumpTo(item, i) {
      if (item.enableTheExternalChain) {
        //如果是外链则跳转新页面
        window.open(this.handelHttps(item.externalLinkUrl), "_blank");
      } else {
        this.$router.push( `/details?hotspotList=${encodeURIComponent(JSON.stringify(this.hotspotList).replace(/%/g, '%25'))}&index=${i}` );
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getArticleListPage();
    },
    //翻页
    handelCurrentChange(page) {
      this.currentPage = page;
      this.getArticleListPage();
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
.hotspotList {
  .tiem {
    .more {
      font-size: 14px;
      color: #999999;
      border: 1px solid #dddddd;
      border-radius: 4px;
      padding: 5px 0;
      width: 66px;
      text-align: center;
    }
    .mm {
      font-size: 30px;
      // font-weight: bold;
      margin: 6px 0 10px;
    }
    .yy {
      font-size: 20px;
      font-weight: bold;
    }
    color: #999999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #eeeeee;
    padding-left: 80px;
  }
  .left {
    .wnzi {
      .text {
        font-size: 14px;
        color: #666666;
        line-height: 25px;
      }
      .title {
        font-size: 20px;
        color: #0e0e0e;
        font-weight: bold;
        margin-bottom: 20px;
        line-height: 35px;
      }
      width: 675px;
      margin-left: 40px;
    }
    .el-image {
      width: 232px;
      height: 192px;
      border-radius: 5px;
    }
  }
  padding: 41px 0;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
}
.hotspotList:hover {
  .title {
    color: #166dba !important;
  }
  // border-bottom: 1px solid #166dba;
}
.tabs {
  .typeList {
    padding: 27px 0;
    font-size: 16px;
    color: #666666;
    margin-right: 58px;
    cursor: pointer;
  }
  .on {
    color: #166dba;
    font-weight: 600;
    border-bottom: 2px solid #166dba;
  }
  .content {
    display: flex;
  }
  background-color: #fbfbfb;
}
.banner {
  .title {
    position: absolute;
    top: 50%;
    left: 26%;
    transform: translate(-50%, -50%);
    p {
      font-size: 24px;
    }
    h5 {
      font-size: 48px;
      margin-bottom: 10px;
    }
    color: white;
  }
  width: 100%;
  height: 460px;
  background: url("../../images/home/za/topBack.png") no-repeat;
  background-size: cover;
  background-position-x: 50%;
  position: relative;
}
.content {
  width: 1200px;
  margin: auto;
}
.container {
  overflow: hidden;
  width: 100%;
}
</style>
